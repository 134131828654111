import React, { useState, useEffect, useRef } from 'react';
import { Play, Pause, Download, Share2 } from 'lucide-react';

import './controls.css'

const Controls = ({currentTime, duration, isPlaying, togglePlayPause, audioRef, src, setIsPlaying}) => {
      const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return <div className="audio-controls">
      <span className="time">{formatTime(currentTime)} / {duration?formatTime(duration):'....'}</span>
      
      <button className="play-pause-btn" onClick={togglePlayPause}>
        {isPlaying ? <Pause size={24} /> : <Play size={24} />}
      </button>
      <audio ref={audioRef} src={src} onEnded={() => setIsPlaying(false)} />
      <a href={src} target='_blank' className="download-btn">
        <Download size={24} />
      </a>
    </div>
}

export default Controls