import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Waveform from './waveform.js'
import Customize from './customize.js'

const root = ReactDOM.createRoot(document.getElementById('waveform'));

const App = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const encoding = queryParams.get('s');

    return [
        <Waveform />,
        <Customize encoding={window.decodeEncoding(encoding)}/>
    ]
}
root.render(
    <React.StrictMode>
        <App></App>
    </React.StrictMode>
);