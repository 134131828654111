import React, { useState } from 'react';
import CustomizeForm from './customizeForm.js';
import copyBlob from './copy.js'


function addQueryParamAndReload(key, value) {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.location.href = url.toString();
}


// dateEncoding = () => {
// 		let encoding = [v1, v2, v3, v4, v5].map(a=>a.replace(/^./, ''))
// 		encoding = encoding.join('')
// 		encoding += hideLyrics ? '0':'1'
// 		encoding += background
// 		localStorage.setItem("encoding", encoding);
// 	}


const defaultState = {
	bg:0,
	hideLyrics:0,
	colors:['#ebdbc6', '#d3bea8', '#ffffff','#333333', '#333333']
}

const Customize = (props) => {
	const [open, setOpen] = useState(false)
	const [state, setState] = useState(props.encoding ? props.encoding : defaultState)

	const setColorIndex = (index, val) => {
		state.colors[index] = val
		setState({...state, colors:state.colors})

	}
	const setBg = (val) => {
		setState({...state, bg:val})
	}
	const setLyrics = (val) => {
		setState({...state, hideLyrics:val})
	}

	const toggleOpen = (v) => {
		setOpen(!open)
		if(!open){
			document.getElementById('sharing-menu').style.display = 'none'
		}else{
			if(v){
				let encoding = state.colors.map(a=>a.replace(/^./, '')).join('')
				encoding += state.hideLyrics ? '1' : '0'
				encoding += state.bg 
				addQueryParamAndReload('s', encoding)
			}
		document.getElementById('sharing-menu').style.display = 'flex'
		}
	}

	return <div>
	{open?
		<CustomizeForm key='form' setLyrics={setLyrics} colors={state.colors} bg={state.bg} hideLyrics={state.hideLyrics} setBg={setBg} setColor={setColorIndex}></CustomizeForm>
		:<div></div>
	}
	<div className='space-around'>
		{open ?
			<div className='remix-container' onClick={()=>toggleOpen(false)}>
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-x"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
				<div className="remix-text">{copyBlob.cancel.chinese}</div>
			</div>:
			null
		}
		<div className='customize-container'  onClick={()=>toggleOpen(true)}>
			<svg className="customize-svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-palette"><circle cx="13.5" cy="6.5" r=".5" fill="currentColor"/><circle cx="17.5" cy="10.5" r=".5" fill="currentColor"/><circle cx="8.5" cy="7.5" r=".5" fill="currentColor"/><circle cx="6.5" cy="12.5" r=".5" fill="currentColor"/><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10c.926 0 1.648-.746 1.648-1.688 0-.437-.18-.835-.437-1.125-.29-.289-.438-.652-.438-1.125a1.64 1.64 0 0 1 1.668-1.668h1.996c3.051 0 5.555-2.503 5.555-5.554C21.965 6.012 17.461 2 12 2z"/></svg>
			<div className="customize-text">{open?copyBlob.finish.chinese:copyBlob.customize.chinese}</div>
		</div>
	</div>
	</div>
}

export default Customize