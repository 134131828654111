import React, { useState } from 'react';
import { PopoverPicker } from "./PopoverPicker";
import './customizeForm.css'
import copyBlob from './copy.js'



const CustomizeForm = (props) => {

	const setBackground = (val) => {
		document.getElementsByClassName('hero')[0].style.backgroundImage = `url(https://aipop-prod.s3.ap-northeast-3.amazonaws.com/bg${val}.png)`;
		props.setBg(val)
	}

	const sv1 = (val) => {
		//app-container
		window.sv1(val)
		props.setColor(0, val)
	}

	const sv2 = (val) => {
		//main-content
		window.sv2(val)	
		props.setColor(1, val)
	}

	const sv3 = (val) => {
		//lyrics-container
		window.sv3(val)
		props.setColor(2, val)
	}
	const sv4 = (val) => {
		//lyrics
		window.sv4(val)
		props.setColor(3, val)
	}

	const sv5 = (val) => {
		//lyrics
		window.sv5(val)
		props.setColor(4, val)		
	}

	const toggleLyrics = (e) => {
		console.log(e.target.value)
		const lyricsContainer = document.getElementsByClassName('lyrics-container')[0] 
		if(lyricsContainer.style.display === 'none'){
			lyricsContainer.style.display = 'block'
			props.setLyrics(false)
		}else{
			lyricsContainer.style.display = 'none'
			props.setLyrics(true)
		}
	}

	return <div className='customize-form'>
		<a href={window.location.origin + window.location.pathname} className='reset'>{copyBlob.reset.chinese}</a>
		<div className='form-row'>
			<div className='form-label'>{copyBlob.mBg.chinese}</div>
			<div className='form-input'>
				<PopoverPicker color={props.colors[0]}  onChange={sv1} presetColors={['#ebdbc6']}/>
			</div>
		</div>
		<div className='form-row'>
			<div className='form-label'>{copyBlob.sBg.chinese}</div>
			<div className='form-input'>
				<PopoverPicker color={props.colors[1]}  presetColors={['#9d7b634d']} onChange={sv2} />
			</div>
		</div>
		{
		props.hideLyrics?  <div/>:
			[<div className='form-row'>
				<div className='form-label'>{copyBlob.lBg.chinese}</div>
				<div className='form-input'>
					<PopoverPicker color={props.colors[2]}  onChange={sv3} />

				</div>
			</div>,
			<div className='form-row'>
				<div className='form-label'>{copyBlob.lTxt.chinese}</div>
				<div className='form-input'>
					<PopoverPicker color={props.colors[3]}  onChange={sv4} />
				</div>
			</div>
		]}
		<div className='form-row'>
			<div className='form-label'>{copyBlob.tTxt.chinese}</div>
			<div className='form-input'>
				<PopoverPicker color={props.colors[4]}  onChange={sv5} />
			</div>
		</div>
		<div className='form-row'>
			<div className='form-label'>{copyBlob.hide.chinese}</div>
			<div className='form-input'>
				<div className='picker'>
					<input className='checkbox' type='checkbox' checked={props.hideLyrics} onClick={toggleLyrics}/>
				</div>
			</div>
		</div>
		<div className='form-image-rows'>
			<h1 className='background-label'>{copyBlob.bg.chinese}:</h1>
			<div className='form-image-row'>
				<div onClick={()=>setBackground(1)} className={`bg1 bg-choice  ${props.bg===1?'active-bg':'no-active-bg'}`}></div>
				<div onClick={()=>setBackground(2)} className={`bg2 bg-choice  ${props.bg===2?'active-bg':'no-active-bg'}`}></div>
				<div onClick={()=>setBackground(3)} className={`bg3 bg-choice  ${props.bg===3?'active-bg':'no-active-bg'}`}></div>
				<div onClick={()=>setBackground(4)} className={`bg4 bg-choice ${props.bg===4?'active-bg':'no-active-bg'}`}></div>
			</div>
			<div className='form-image-row'>
				<div onClick={()=>setBackground(5)} className={`bg5 bg-choice  ${props.bg===5?'active-bg':'no-active-bg'}`}></div>
				<div onClick={()=>setBackground(6)} className={`bg6  bg-choice ${props.bg===6?'active-bg':'no-active-bg'}`}></div>
				<div onClick={()=>setBackground(0)} className={`bg bg-choice  ${props.bg===0?'active-bg':'no-active-bg'}`}></div>
			</div>
		</div>
	</div>
}


export default CustomizeForm