import React, { useState, useEffect, useRef } from 'react';
import Controls from './controls.js'
import copyBlob from './copy.js'

import './waveform.css'

const getWaveformData = async(audioUrl, numSamples = 60) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  try {
    const response = await fetch(audioUrl);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const arrayBuffer = await response.arrayBuffer();

    if (arrayBuffer.byteLength === 0) {
      throw new Error('Received empty ArrayBuffer');
    }

    let audioBuffer;
    try {
      audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    } catch (decodeError) {
      throw new Error('Failed to decode audio data. Ensure the file format is supported and the file is not corrupted.');
    }


    const rawData = audioBuffer.getChannelData(0);
    const blockSize = Math.floor(rawData.length / numSamples);
    const waveformData = [];

    for (let i = 0; i < numSamples; i++) {
      let blockStart = blockSize * i;
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum += Math.abs(rawData[blockStart + j]);
      }
      waveformData.push(sum / blockSize);
    }

    const multiplier = Math.pow(Math.max(...waveformData), -1);
    return waveformData.map(n => n * multiplier * 100);
  } catch (error) {
    throw error;
  }
}

// Usage example:
// getWaveformData('/path/to/audio/file.mp3').then(waveformData => {
//   console.log(waveformData);
//   // Use this data with your SongTimeline component
// });


export default ({ title, artist, lyrics }) => {
  const [waveformData, setWaveformData] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(false);
  const audioRef = useRef(null);
  const timelineRef = useRef(null);

  const currentPath = window.location.pathname;
  const _id = currentPath.split('/').pop()

  const src = `https://aipop-prod.s3.ap-northeast-3.amazonaws.com/${_id}.mp3`

  useEffect(() => {
    getWaveformData(src).catch((e)=>{
      console.error(e)
      setError(true)
    }).then(data => {
      if(data){
        setWaveformData(data);
      }
    })
  }, [src]);  // Changed from [Waveform] to [src]

  useEffect(() => {
    const audio = audioRef.current;
    
    if(waveformData.length){
          const handleLoadedMetadata = () => {
        setDuration(audio.duration);
      };

      const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime);
      };

      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [waveformData]);


  if(error){
    return <div>{copyBlob.songError.chinese}</div>
  }

  if(waveformData.length){
  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };


  const handleSeek = (e) => {
    const timeline = timelineRef.current;
    const rect = timeline.getBoundingClientRect();
    const seekPosition = (e.clientX - rect.left) / rect.width;
    audioRef.current.currentTime = seekPosition * duration;
  };


  return (
    <div className="song-timeline">
      <div className="timeline" ref={timelineRef} onClick={handleSeek}>
        <div className="waveform">
          {waveformData.map((height, index) => (
            <div 
              key={index} 
              className="bar" 
              style={{ 
                height: `${height}%`,
                backgroundColor: index / waveformData.length < currentTime / duration ? '#1db954' : '#ddd'
              }}
            />
          ))}
        </div>
        <div className="progress" style={{ width: `${(currentTime / duration) * 100}%` }} />
      </div>
        <Controls currentTime={currentTime} duration={duration} isPlaying={isPlaying} togglePlayPause={togglePlayPause} audioRef={audioRef} src={src} setIsPlaying={setIsPlaying}/>
    </div>
  );
  }
  return <div>{copyBlob.loading.chinese}...</div>;  // Added loading state
};