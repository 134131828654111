const copyBlob = {
	mBg:{
		english:'Main Background',
		chinese:'主背景顏色'
	},
	sBg:{
		english:'Secondary Background',
		chinese:'其他背景顏色'
	},
	lBg:{chinese:'歌詞背景顏色'},
	lTxt:{chinese:'歌詞顏色'},
	tTxt:{chinese:'歌名顏色'},
	customize:{chinese:'客製化'},
	cancel:{chinese:'取消'},
	finish:{chinese:'完畢'},
	reset:{chinese:'重置'},
	bg:{chinese:'背景'},
	hide:{chinese:'隱藏歌詞'},
	loading:{chinese:'載入中'},
	songError:{chinese:" 載入歌曲時出錯", english:"Error loading song"}
}


export default copyBlob